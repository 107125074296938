<template>
  <div>
    <template v-if="data">
      <!-- Request Form -->
      <div class="p-2 flex items-center justify-between">
        <div>
          <div class="flex items-center gap-x-3">
            <span
              v-if="showEditButton"
              @click="$emit('edit-request')"
              class="
                material-icons
                cursor-pointer
                hover:scale-110
                duration-150
                transform-gpu
              "
            >
              create
            </span>
            <h1 class="font-bold">{{ title ? title : titleVariable }}</h1>
          </div>
          <p class="">
            From:
            <span v-if="data?.application_manager.profile">{{
              `${data?.application_manager.profile.first_name} ${data?.application_manager.profile.last_name}`
            }}</span>
          </p>
        </div>

        <base-button
          v-if="
            ((showOfferButtons && !filterApplicant.offer) ||
              (showOfferButtons &&
                filterApplicant?.offer?.status === 'declined')) &&
            filterApplicant.offers.length < 1
          "
          @click="confirmOfferFunc"
          >Submit Offer</base-button
        >
        <base-button
          v-if="
            filterApplicant?.offers?.length > 0 &&
            filterApplicant?.offers[0]?.status === 0
          "
          >Offer Submitted</base-button
        >

        <base-button
          v-if="
            filterApplicant?.offers[0]?.status === 1 &&
            filterApplicant?.status != 1
          "
          >Offer Accepted</base-button
        >

        <base-button v-if="filterApplicant?.status === 1"
          >Candidate Hired</base-button
        >

        <template
          v-if="
            showOfferButtons &&
            filterApplicant.offer &&
            filterApplicant.status === 'made'
          "
        >
          <base-button
            mode="cancel"
            @click="$emit('cancel-offer', this.filterApplicant.offer.id)"
            >Decline offer</base-button
          >
        </template>
      </div>
      <!-- Icon / Request Info -->
      <div class="flex w-full mb-2">
        <div class="text-center text-grey bg-white rounded-xl ml-2 shadow-md">
          <p class="text-grey font-semibold text-lg mx-3">
            {{ data.contract_type.toUpperCase() }}
          </p>
          <div class="relative">
            <span class="material-icons text-7xl mx-3" :class="iconClass">
              person
            </span>
          </div>
        </div>
        <div
          class="
            bg-white
            rounded-xl
            w-full
            flex flex-col
            mx-2
            justify-around
            shadow-md
          "
        >
          <h3 class="font-bold text-lg pl-2">Request Information</h3>
          <div class="flex pl-2">
            <p>
              Job title:
              <span>{{ capitalizeFirstLetter(data.job_title) }}</span>
            </p>
          </div>
          <div class="flex pl-2">
            <p>
              Reports to:
              {{ capitalizeFirstLetter(data.reporting_to) }}
            </p>
          </div>
        </div>
      </div>
      <!-- Manager Statement -->
      <div
        class="bg-white rounded-xl m-2 pb-2 min-h-1/4 flex flex-col shadow-md"
      >
        <h3 class="font-bold mt-2 ml-2 text-lg">Manager Statement</h3>
        <p class="mx-2">Submitted: {{ convertedTime(data.created_at) }}</p>
        <div class="flex">
          <p class="mx-2">
            <b>Reason For Recruitment: </b>
            <span>{{
              capitalizeFirstLetter(data.reason_for_recruitment)
            }}</span>
          </p>
        </div>
        <div
          class="
            border border-grey
            rounded-l
            mx-2
            flex-grow
            h-60
            overflow-scroll
          "
        >
          <p class="ml-2">
            <span v-html="data.comment"></span>
          </p>
        </div>
      </div>
      <!-- Job Spec / Salary Info / Rota Info -->
      <div class="mx-2">
        <!-- Job Spec -->
        <div v-if="title != 'New Offer'" class="flex">
          <div
            class="
              bg-white
              shadow-md
              rounded-xl
              text-center
              h-28
              flex
              justify-center
              items-center
              m-1
              w-1/2
            "
          >
            <div>
              <h3 class="font-bold text-lg">Job Specification</h3>
              <!-- <router-link :to="{ name: 'hq-job-person-specification' }"> -->
              <p
                @click="toggleJobSpecModal"
                class="text-teal text-xs mt-2 cursor-pointer hover:underline"
              >
                View more
              </p>
              <!-- </router-link> -->
            </div>
          </div>

          <!-- Salary Info -->
          <div
            class="
              flip
              shadow-md
              cursor-pointer
              bg-white
              rounded-xl
              text-center
              h-28
              flex
              justify-center
              items-center
              m-1
              w-1/2
            "
          >
            <div
              class="
                flip-content
                w-full
                h-full
                transition transition-transform
                duration-300
              "
            >
              <div
                class="
                  flip-front
                  w-full
                  h-full
                  flex flex-col
                  justify-center
                  items-center
                "
              >
                <h3 class="font-bold text-lg">Salary Information</h3>
                <p class="text-teal text-xs mt-2 underline">Hover</p>
              </div>
              <div
                class="flip-back w-full h-full flex justify-center items-center"
              >
                <p class="text-grey">
                  {{ capitalizeFirstLetter(data.starting_salary) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Joining Date and salaray amount -->
        <div
          v-if="title === 'New Offer' || title === 'Current Offer'"
          class="flex"
        >
          <div
            class="
              bg-white
              shadow-md
              rounded-xl
              text-center
              h-28
              flex
              justify-center
              items-center
              m-1
              w-1/2
            "
          >
            <div>
              <div class="ml-3">
                <label for="terminationDate" class="font-bold float-left"
                  >Joining Date:</label
                >
                <br />
                <input
                  required
                  name="terminationDate"
                  class="w-full h-12 rounded-md shadow-md shadow-black"
                  type="date"
                  v-model="joiningDate"
                  :disabled="filterApplicant?.offers?.length > 0 ? true : false"
                  :class="
                    v$.joiningDate.$error === true ? 'border-2 border-red' : ''
                  "
                />
              </div>
              <!-- <router-link :to="{ name: 'hq-job-person-specification' }"> -->

              <!-- </router-link> -->
            </div>
          </div>

          <!-- Salary Info -->
          <div
            class="
              bg-white
              shadow-md
              cursor-pointer
              rounded-xl
              text-center
              h-28
              flex
              justify-center
              items-center
              m-1
              w-1/2
            "
          >
            <div>
              <div class="ml-3">
                <label for="terminationDate" class="font-bold float-left"
                  >Salary Amount:</label
                >
                <br />
                <input
                  required
                  name="terminationDate"
                  class="w-full h-12 rounded-md shadow-md shadow-black"
                  type="number"
                  placeholder="e.g £50"
                  v-model="joiningSalary"
                  :disabled="filterApplicant?.offers?.length > 0 ? true : false"
                  :class="
                    v$.joiningSalary.$error === true
                      ? 'border-2 border-red'
                      : ''
                  "
                />
              </div>
              <!-- <router-link :to="{ name: 'hq-job-person-specification' }"> -->

              <!-- </router-link> -->
            </div>
          </div>
        </div>
        <!-- action buttons for offer status -->
        <div
          v-if="
            (title === 'New Offer' || title === 'Current Offer') &&
            filterApplicant?.offers?.length > 0 &&
            showStatus === true &&
            filterApplicant?.status != 1
          "
          class="bg-white rounded-xl"
        >
          <h3 class="font-bold text-lg pl-2">Select Offer Status:</h3>
          <div
            class="
              flex
              flex-row
              bg-white
              shadow-md
              rounded-xl
              text-center
              h-28
              justify-center
              items-center
              m-1
              w-full
            "
          >
            <base-button
              :animateButton="animateButton === 1 ? true : false"
              @click="offerStatusFunc(1)"
              >Accepted</base-button
            ><base-button
              mode="cancel"
              :animateButton="animateButton === 0 ? true : false"
              @click="offerStatusFunc(0)"
              >Declined</base-button
            >
            <base-button
              v-if="filterApplicant?.offers[0]?.status === 1"
              @click="hireCandidate(filterApplicant?.user_id)"
              >Hire Candidate</base-button
            >
            <base-button
              :animateButton="animateButton === 5 ? true : false"
              mode="pause"
              @click="offerStatusFunc(5)"
              >Discarded</base-button
            >
          </div>
        </div>
        <!-- Rota Info -->
        <div
          v-if="title != 'New Offer' || !title"
          class="bg-white rounded-xl m-1 w-full shadow-md"
        >
          <h3 class="font-bold mt-2 ml-2 mb-1 text-lg">Rota Information</h3>
          <div class="flex">
            <p class="ml-2">
              Rota Name:
              {{ capitalizeFirstLetter(data.work_patterns[0].name) }}
            </p>
          </div>
          <div class="">
            <!-- Rota Information -->
            <rota-card
              :showSelect="false"
              :bgColour="'bg-white'"
              :showTitle="false"
              :data="data.work_patterns[0].work_timings"
            ></rota-card>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import rotaCard from "@/components/ui/baseComponents/rotaCardWithData.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  components: { rotaCard },
  emits: [
    "toggleJobSpecModal",
    "confirm-offer",
    "cancel-offer",
    "edit-request",
    "offer-ammendments",
  ],
  props: {
    data: {
      type: Object,
      required: true,
    },
    showOfferButtons: {
      type: Boolean,
      default: false,
    },
    showEditButton: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      jobSpecModalOpen: false,
      titleVariable: "Staff Request Form",
      v$: useVuelidate(),
      joiningDate: null,
      joiningSalary: null,
      animateButton: -1,
    };
  },

  validations() {
    return {
      joiningDate: { required },
      joiningSalary: { required },
    };
  },

  created() {
    this.joiningDate = this.filterApplicant?.offers[0]?.joining_date;
    if(this.filterApplicant?.offers[0]?.amendments.length > 0){
      this.joiningSalary = this.filterApplicant?.offers[0]?.amendments[this.filterApplicant?.offers[0]?.amendments.length - 1]?.amount;
    }
    else{
      this.joiningSalary = this.filterApplicant?.offers[0]?.amount;
    }
    this.$emit("offer-ammendments", this.filterApplicant);
  },

  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleJobSpecModal() {
      this.$emit("toggleJobSpecModal");
      //   this.jobSpecModalOpen = !this.jobSpecModalOpen;
    },
    confirmOfferFunc() {
      this.v$.$validate();
      if (this.v$.$error === true) {
        return;
      } else {
        const obj = {
          joining_date: this.joiningDate,
          amount: this.joiningSalary,
        };
        this.$emit("confirm-offer", obj);
      }
    },
    async offerStatusFunc(value) {
      try {
        this.animateButton = value;
        const offerPayload = {
          offer: this.filterApplicant?.offers[0]?.id,
          status: value,
        };
        await this.$store.dispatch("REnewHire/updateOffer", offerPayload);
        this.$toast.success(`Updated Successful`);
        this.$router.push({
          name: "re-offers-inbox",
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
        this.$router.push({
          name: "re-interviews-offers",
        });
      }
    },
    async hireCandidate(value) {
      try {
        const payload = {
          candidate: value,
        };
        await this.$store.dispatch("REnewHire/hireCandidate", payload);
        this.$toast.success(`Candidate Hired`);
        this.$router.push({
          name: "re-offers-inbox",
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
        this.$router.push({
          name: "re-interviews-offers",
        });
      }
    },
  },
  computed: {
    iconClass() {
      return {
        "text-teal": this.data.contract_type === "Permanent",
        "text-orange": this.data.contract_type === "Casual",
        "text-green": this.data.contract_type === "Fixed-term",
        "text-red": this.data.contract_type === "Zero-hour",
      };
    },
    filterApplicant() {
      return this.data.applicants
        .filter((user) => user.user_id == this.$route.params.userId)
        .shift();
    },
  },
};
</script>

<style scoped>
#forHeight {
  height: calc(100vh - 70px);
}

.flip-content {
  transform-style: preserve-3d;
}
.flip:hover .flip-content {
  transform: rotateY(180deg);
  transition: transform 0.3s;
}
.flip-front,
.flip-back {
  position: absolute;

  backface-visibility: hidden;
}
.flip-back {
  transform: rotateY(180deg);
}
.recent-activity .res-activity-list li:before {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.recent-activity .res-activity-list li:before {
  width: 10px;
  height: 10px;
  left: -32px;
  top: 5px;
  border: 2px solid #ed8b00;
  margin-right: 15px;
  z-index: 2;
  background: #fff;
}
.recent-activity .res-activity-list li:hover:before {
  border: 4px solid #ed8b00;
  cursor: pointer;
}
.recent-activity .res-activity-list:after {
  content: "";
  border: 1px solid #e8edee;
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 4px;
}
</style>
